import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function PressItem({ image, heading, description, slug }) {
  return (
    <Link to={`/press/${slug}`}>
      <div className="mb-[53px] flex flex-col items-center justify-center cursor-pointer">
        <GatsbyImage image={image} alt={heading} />
        <div className="flex flex-col items-center justify-center mt-[19px]">
          <p className="text-[15px] leading-[177.4%] text-black">{heading}</p>
          <span className="h-[1px] bg-black block mt-[6px] w-[77px]"></span>
        </div>
        <p className="text-xs mt-[6px] leading-[130%] text-center max-w-[230px]">
          {description}
        </p>
      </div>
    </Link>
  );
}
