import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Heading from "../components/common/heading";
import Button from "../components/common/button";
import NewsLetters from "../components/common/newsletter";
import PressItem from "../components/press/pressItem";
import Seo from "../components/common/SEO";

export default function Press({ data }) {
  const { sanityMixPress } = data;
  return (
    <Layout>
      <Seo
        title={sanityMixPress?.seo?.metaTitle}
        description={sanityMixPress?.seo?.metaDescription}
      />
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityMixPress?.title}
              caption={sanityMixPress?.caption}
            />
            <div className="mt-[52px] grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-6">
              {sanityMixPress?.releases.length > 0 &&
                sanityMixPress?.releases.map((item, index) => {
                  return (
                    <PressItem
                      key={index}
                      description={item?.excerpt}
                      heading={item?.title}
                      image={item?.thumbnail?.asset?.gatsbyImageData}
                      slug={item?.slug?.current}
                    />
                  );
                })}
            </div>
            <div className="mt-[51px]">
              <Button text="VIEW MORE" type="dark" />
            </div>
          </div>
        </div>
        <div className=" mt-[82px]">
          <NewsLetters small={true} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityMixPress {
      title
      caption
      releases {
        title
        excerpt
        slug {
          current
        }
        thumbnail {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              fit: FILLMAX
              height: 215
              width: 286
            )
          }
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
